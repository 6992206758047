var isLoggedIn;
var modalInstances;
var loginReason = "resources";
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDH3HIEbeJqumgXNuPSRCWFH6kI7j8cl0M",
    authDomain: "infosite-6e22f.firebaseapp.com",
    databaseURL: "https://infosite-6e22f.firebaseio.com",
    projectId: "infosite-6e22f",
    storageBucket: "infosite-6e22f.appspot.com",
    messagingSenderId: "749349288415",
    appId: "1:749349288415:web:7354084574badb86"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

document.addEventListener('DOMContentLoaded', function () {
    var elems = document.querySelectorAll('.sidenav');
    var instances = M.Sidenav.init(elems, {});
    var modalElems = document.querySelectorAll('.modal');
    modalInstances = M.Modal.init(modalElems, {});
});

function signIn() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(function (result) {
        M.toast({ html: 'Login Success', classes: "green" });
        modalInstances[1].close();
        if (loginReason === "booking") {
            modalInstances[0].open();
        }
    }).catch(function (error) {
        M.toast({ html: 'Login Failed! Please try again', classes: "red" });
    });

}
var signInBtn = document.getElementById("signin-btn")
signInBtn.addEventListener("click", function (event) {
    event.preventDefault();
    signIn();
})

firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        isLoggedIn = true;
    } else {
        isLoggedIn = false;
    }
});
var bookingForm = document.getElementById("booking-form");

bookingForm.addEventListener("submit", function (event) {
    event.preventDefault();
    var textFields = ["eventDate", "email", "location", "duration", "phoneNumber", "numberOfPeople"];
    var payload = {};
    textFields.forEach(field => {
        payload[field] = document.getElementById(field).value || "";
    });
    db.collection("bookings").add(payload)
        .then(function () {
            M.toast({ html: 'Success! Message Sent', classes: "green" });
            modalInstances[0].close();
        })
        .catch(function (error) {
            M.toast({ html: 'An error occurred! Please try again', classes: "red" });
        });
});

var bookingBtn = document.getElementById("activate-booking");
bookingBtn.addEventListener("click", function (event) {
    event.preventDefault();
    checkAuth()
})

function checkAuth() {
    if (isLoggedIn) {
        modalInstances[0].open();
    } else {
        modalInstances[1].open()
        loginReason = "booking";
    }
}

var resourceSpan = document.querySelectorAll(".resource-link");
resourceSpan.forEach(doc => doc.addEventListener("click", function (event) {
    event.preventDefault()
    var fileTarget = event.target.dataset.target;
    if (isLoggedIn) {
        window.open(fileTarget, '_blank');
    } else {
        modalInstances[1].open()
    }
}))